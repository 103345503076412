<template>
  <div class="scatter-chart">
    <div v-show="data" :key="key" style="width: 100%; height: 100%;" ref="content"></div>
    <NoData v-show="!data" />
  </div>
</template>

<script>
import * as echarts from "echarts";
import { chartMax } from "./utils"
import NoData from "@/components/NoData.vue"

var mockData = {
  survey_sn: "ox6am5Y8",
  answer_num: 2,
  recommend_factor: [
    {
      option_title: "售前耐心解答",
      recommend_num: 2,
      not_recommend_num: 0,
      recommend_num_ratio: "100.00",
      not_recommend_ratio: "0.00",
    },
    {
      option_title: "服务态度良好",
      recommend_num: 1,
      not_recommend_num: 0,
      recommend_num_ratio: "50.00",
      not_recommend_ratio: "0.00",
    },
    {
      option_title: "客服专业度",
      recommend_num: 2,
      not_recommend_num: 0,
      recommend_num_ratio: "100.00",
      not_recommend_ratio: "0.00",
    },
    {
      option_title: "客服响应速度",
      recommend_num: 2,
      not_recommend_num: 0,
      recommend_num_ratio: "100.00",
      not_recommend_ratio: "0.00",
    },
    {
      option_title: "联系客服途径",
      recommend_num: 2,
      not_recommend_num: 0,
      recommend_num_ratio: "100.00",
      not_recommend_ratio: "0.00",
    },
    {
      option_title: "问题处理结果",
      recommend_num: 1,
      not_recommend_num: 0,
      recommend_num_ratio: "50.00",
      not_recommend_ratio: "0.00",
    },
    {
      option_title: "解决问题能力",
      recommend_num: 0,
      not_recommend_num: 2,
      recommend_num_ratio: "0.00",
      not_recommend_ratio: "100.00",
    },
    {
      option_title: "问题解决时效性",
      recommend_num: 1,
      not_recommend_num: 1,
      recommend_num_ratio: "50.00",
      not_recommend_ratio: "50.00",
    },
  ],
};

export default {
  components: {
    NoData
  },
  data() {
    return {
      key: 0,
      echart: null,
      resize: () => {},
      data: null
    };
  },
  mounted() {
    // this.init();
    this.resize = () => {
      this.echart?.resize();
    };
    window.addEventListener("resize", this.resize);
    setTimeout(() => {
      this.resize();
    }, 100);
  },

  destroyed() {
    window.removeEventListener("resize", this.resize);
  },

  methods: {
    // 使用此接口初始化图表，接入时mounted中去掉 this.init()
    init(data) {

      this.key = Date.now();

      if(!data) {
        data = mockData;
      }

      this.data = data != mockData;

      this.$nextTick(() => {
        this.exec(data);
      })
    },
    exec(data) {
      
      var arrX = data.recommend_factor.map(x => Number(x.not_recommend_ratio) / 100).sort();
      var arrY = data.recommend_factor.map(x => Number(x.recommend_num_ratio) / 100).sort();

      var resX = chartMax(arrX);
      var resY = chartMax(arrY);

      this.echart = echarts.init(this.$refs.content);
      // 指定图表的配置项和数据
      var option = ({
        title: [
          { text: "推荐因子", left: "4%", top: "7%", textStyle: { fontSize: 12, color: "#F59646", fontWeight: "bold"}, backgroundColor: "#F5964622" },
          { text: "无差异因子", left: "4%", bottom: "7%", textStyle: { fontSize: 12, color: "#397ADC", fontWeight: "bold"}, backgroundColor: "#397ADC22" },
          { text: "必要因子", right: "4%", top: "7%", textStyle: { fontSize: 12, color: "#33A6C7", fontWeight: "bold"}, backgroundColor: "#33A6C722" },
          { text: "不推荐因子", right: "4%", bottom: "7%", textStyle: { fontSize: 12, color: "#E24D4D", fontWeight: "bold"}, backgroundColor: "#E24D4D22" },
        ],
        grid: {
          top: "7%",
          bottom: "7%",
          left: "4%",
          right: "4%",
        },
        xAxis: [
          {
            show: true,
            position: "bottom",
            axisLine: { onZero: false },
            splitLine: { show: false },
            min: 0,
            max: Math.max(resX.up, resY.up),
          },
          { show: true, position: "top", axisLine: { onZero: false } },
        ],
        yAxis: [
          {
            show: true,
            axisLine: { onZero: false },
            position: "left",
            splitLine: { show: false },
            min: 0,
            max: Math.max(resX.up, resY.up),
          },
          { show: true, axisLine: { onZero: false }, position: "right" },
        ],
        series: [
          {
            data: [],
            type: "scatter",
          },

          ...data.recommend_factor.map(x => ({
            label: {
              show: true,
              formatter: x.option_title,
              offset: [0, 20],
              color: "#333",
            },
            symbolSize: 5,
            data: [
              [Number(x.not_recommend_ratio) / 100, Number(x.recommend_num_ratio) / 100],
            ],
            type: "scatter",
          })),

          // {
          //   label: {
          //     show: true,
          //     formatter: "产品舒适度",
          //     offset: [0, 20],
          //   },
          //   symbolSize: 5,
          //   data: [
          //     [10.0, 88.04],
          //     [88.07, 66.95],
          //   ],
          //   type: "scatter",
          // },
          // {
          //   label: {
          //     show: true,
          //     formatter: "活动真实性",
          //     offset: [0, 20],
          //   },
          //   symbolSize: 5,
          //   data: [
          //     [90.0, 88.04],
          //     [18.07, 66.95],
          //   ],
          //   type: "scatter",
          // },
          // {
          //   label: {
          //     show: true,
          //     formatter: "物流效率",
          //     offset: [0, 20],
          //   },
          //   symbolSize: 5,
          //   data: [
          //     [90.0, 44.04],
          //     [68.07, 66.95],
          //   ],
          //   type: "scatter",
          // },

          {
            type: "line",
            markLine: {
              symbol: ["none", "none"], //去掉箭头
              data: [
                {
                  name: "Y",
                  valueDim: "close",
                  yAxis: resY.avg, // 标记线y轴的值
                },
                {
                  name: "X",
                  valueDim: "close",
                  xAxis: resX.avg, // 标记线x轴的值
                },
              ],
            },
          },
        ],
      });
      // 使⽤刚指定的配置项和数据显示图表。
      this.echart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.scatter-chart {
  width: 100%;
  height: 600px;
}
</style>