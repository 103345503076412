export const chartMax = (arr) => {
  
  var max = Math.max(...arr);

  var avg = arr.reduce((ctx, cur) => (ctx + cur), 0) / arr.length;
  
  var minRange = arr.reduce((ctx, cur) => {
    if(ctx.last == -Infinity) {
      return {
        value: ctx.value,
        last: cur
      }
    }
    var abs = Math.abs(ctx.last - cur);
    if(abs == 0) {
      return {
        value: ctx.value,
        last: cur
      }
    }
    return {
      value: Math.min(abs, ctx.value),
      last: cur
    }
  }, {
    value: +Infinity,
    last: -Infinity
  });

  // 上限
  var up = Number(((max + minRange.value) * 10).toFixed(0)) / 10;

  return {
    avg,
    up
  }
}