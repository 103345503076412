<template>
  <div class="recommendation">
    <!-- 顶部查询 -->
    <SearchCondition @onSearch="search" style="margin-bottom: 16px" />
    <!-- 总体分析 -->
    <white-card title="总体分析">
      <div class="ensemble">
        <div class="e-card">
          <div class="ec-top">
            <div class="ec-title">NPS</div>
            <!-- <a-icon class="icon" type="question-circle" /> -->
          </div>
          <div class="ec-main">
            <div ref="nps">
              <BaseChart :chartOption="npsOption" height="100%" />
            </div>
          </div>
        </div>
        <div class="e-card">
          <div class="ec-top">
            <div class="ec-title">用户分布</div>
          </div>
          <div class="ec-main">
            <div ref="user">
              <BaseChart :chartOption="userOption" height="100%" />
            </div>
          </div>
        </div>
      </div>
    </white-card>
    <!-- 趋势分析 -->
    <white-card title="趋势分析">
      <template #topRight>
        <date-picker @change="changeDate" />
      </template>
      <div class="trend">
        <div ref="trend">
          <BaseChart :chartOption="trendOption" height="100%" />
        </div>
      </div>
    </white-card>
    <!-- 推荐/不推荐原因 -->
    <white-card title="推荐/不推荐原因" hideLine>
      <div class="cause">
        <common-tabs :list="tabs" ref="commonTabs" @change="changeTab" />
        <div class="cause-main">
          <div class="c-top">
            <ScatterChart ref="ScatterChart" />
          </div>
          <div class="c-center">
            <div ref="cause">
              <BaseChart :chartOption="causeOption" height="100%" />
            </div>
          </div>
          <div class="c-bottom">
            <div class="tr th">
              <div class="td">序号</div>
              <div class="td">
                <a-dropdown>
                  <a @click="(e) => e.preventDefault()" style="color: #2f54eb">
                    {{ currentDropdownMenu.title }} <a-icon type="down" />
                  </a>
                  <a-menu slot="overlay">
                    <a-menu-item v-for="menu in dropdownMenu" :key="menu.value">
                      <a
                        href="javascript:;"
                        @click="currentDropdownMenu = menu"
                        >{{ menu.title }}</a
                      >
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div>
            </div>
            <div
              class="tr"
              v-for="(table, index) in tableData.slice(
                (page - 1) * 10,
                (page - 1) * 10 + 10
              )"
              :key="index"
            >
              <div class="td">{{ index + 1 }}</div>
              <div class="td">{{ table.option_title }}</div>
            </div>
            <div class="page">
              <a-pagination
                v-model="page"
                :total="tableData.length"
                show-less-items
              />
            </div>
          </div>
        </div>
      </div>
    </white-card>
  </div>
</template>

<script>
import { qdic } from '@/utils.js';
import * as echarts from 'echarts';
import SearchCondition from '../../../components/SearchCondition.vue';
import WhiteCard from '../../../components/WhiteCard.vue';
import DatePicker from '../../../components/DatePicker.vue';
import CommonTabs from '../../../components/CommonTabs.vue';
import BaseChart from '../../../components/echarts/BaseChart.vue';
import ScatterChart from './components/ScatterChart.vue';

const tabs = Object.keys(qdic).map((key) => ({ key, value: qdic[key] }));

export default {
  components: {
    SearchCondition,
    WhiteCard,
    DatePicker,
    CommonTabs,
    BaseChart,
    ScatterChart,
  },
  data() {
    return {
      tabs,
      sn: '',
      date: {},
      params: {},
      current: '',
      npsOption: {},
      userOption: {},
      trendOption: {},
      causeOption: {},
      tableList: [],
      dropdownMenu: [
        {
          title: '推荐的原因',
          value: 'recommend_cause',
        },
        {
          title: '不推荐的原因',
          value: 'not_recommend_cause',
        },
      ],
      currentDropdownMenu: {
        title: '推荐的原因',
        value: 'recommend_cause',
      },
      // 推荐-推荐因子
      recommendFactors: [],
      page: 1,
    };
  },

  computed: {
    tableData() {
      const data = this.tableList.find(
        (list) => list.survey_sn === this.current
      );
      this.page = 1;
      return data?.[this.currentDropdownMenu.value] || [];
    },
  },

  async mounted() {
    await Promise.all([
      this.getNps(),
      this.getUser(),
      this.getTrend(),
      this.getTableList(),
      this.getRecommendFactor(),
    ]);
  },

  methods: {
    // 顶部查询
    search(value) {
      this.params = value;
      this.getNps();
      this.getUser();
      this.getTrend();
      this.getTableList(), this.getRecommendFactor();
    },

    // 选择日期
    changeDate(value) {
      this.date = value;
      this.getTrend();
    },

    // 改变tab
    changeTab(value) {
      this.current = value.key;

      this.$nextTick(() => {
        var target = this.recommendFactors.find(
          (x) => x.survey_sn == value.key
        );
        this.$refs.ScatterChart.init(target);
        this.updateHorizonChart(target);
      });
    },

    // 获取nps分析
    async getNps() {
      try {
        const { data } = await this.$axios.post('/api/dada/analyze_nps', {
          complete_start_time: this.params.month?.[0],
          complete_end_time: this.params.month?.[1],
          shop_name: this.params.shop,
        });
        this.npsOption = {
          series: [
            {
              type: 'gauge',
              startAngle: 180,
              endAngle: 0,
              center: ['50%', '85%'],
              radius: '130%',
              min: 0,
              max: 100,
              splitNumber: 8,
              axisLine: {
                lineStyle: {
                  width: 4,
                  color: [
                    [0.25, '#FF6E76'],
                    [0.5, '#FDDD60'],
                    [0.75, '#58D9F9'],
                    [1, '#7CFFB2'],
                  ],
                },
              },
              pointer: {
                icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                length: '12%',
                width: 10,
                offsetCenter: [0, '-60%'],
                itemStyle: {
                  color: 'auto',
                },
              },
              axisTick: {
                length: 12,
                lineStyle: {
                  color: 'auto',
                  width: 2,
                },
              },
              splitLine: {
                length: 20,
                lineStyle: {
                  color: 'auto',
                  width: 4,
                },
              },
              axisLabel: {
                color: '#464646',
                fontSize: 14,
                distance: -50,
                rotate: 'tangential',
                formatter: function (value) {
                  if (value === 0.875) {
                    return 'Grade A';
                  } else if (value === 0.625) {
                    return 'Grade B';
                  } else if (value === 0.375) {
                    return 'Grade C';
                  } else if (value === 0.125) {
                    return 'Grade D';
                  }
                  return '';
                },
              },
              title: {
                offsetCenter: [0, '-10%'],
                fontSize: 14,
              },
              detail: {
                fontSize: 20,
                offsetCenter: [0, '-35%'],
                valueAnimation: true,
                color: 'inherit',
                formatter: function (value) {
                  return value + '%';
                },
              },
              data: [
                {
                  value: data.data.npsValue,
                  name: 'NPS值',
                },
              ],
            },
          ],
        };
      } catch (error) {
        console.log(error);
        this.npsOption = null;
      }
    },

    // 获取用户分布
    async getUser() {
      try {
        const { data } = await this.$axios.post(
          '/api/dada/user_distribution_nps',
          {
            complete_start_time: this.params.month?.[0],
            complete_end_time: this.params.month?.[1],
            shop_name: this.params.shop,
          }
        );
        this.userOption = {
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)',
          },
          legend: {
            type: 'scroll',
            width: '80%',
            data: [
              '推荐者',
              '中立者',
              '贬损者',
              '1分',
              '2分',
              '3分',
              '4分',
              '5分',
              '6分',
              '7分',
              '8分',
              '9分',
              '10分',
            ],
          },
          series: [
            {
              center: ['50%', '55%'],
              name: '占比',
              type: 'pie',
              radius: [0, '45%'],
              label: {
                position: 'inner',
                fontSize: 14,
              },
              labelLine: {
                show: false,
              },
              data: [
                { value: data.data.derogatory, name: '贬损者' },
                { value: data.data.neutral, name: '中立者' },
                { value: data.data.recommend, name: '推荐者' },
              ],
            },
            {
              center: ['50%', '55%'],
              name: '占比',
              type: 'pie',
              radius: ['55%', '70%'],
              label: {
                normal: {
                  show: true,
                  formatter: function (params) {
                    if (params.value > 0) {
                      return `  {b|${params.name}：}${params.value}  {per|${params.percent}%}  `;
                    } else {
                      return '';
                    }
                  },
                  backgroundColor: '#F6F8FC',
                  borderColor: '#8C8D8E',
                  borderWidth: 1,
                  borderRadius: 4,
                  rich: {
                    b: {
                      color: '#4C5058',
                      fontSize: 12,
                      fontWeight: 'bold',
                      lineHeight: 24,
                    },
                    per: {
                      color: '#fff',
                      backgroundColor: '#4C5058',
                      padding: [2, 3],
                      borderRadius: 4,
                    },
                  },
                },
              },
              labelLine: {
                show: false,
              },
              data: data.data.userDistributionNpsVOS.map((user) => ({
                value: user.scoreNumber,
                name: `${user.score}分`,
              })),
            },
          ],
        };
      } catch (error) {
        console.log(error);
        this.userOption = null;
      }
    },

    // 获取趋势分析
    async getTrend() {
      try {
        const { data } = await this.$axios.post('/api/dada/analyze_nps_trend', {
          complete_start_time: this.params.month?.[0],
          complete_end_time: this.params.month?.[1],
          shop_name: this.params.shop,
          start_time: this.date.start,
          end_time: this.date.end,
        });
        this.trendOption = {
          xAxis: {
            type: 'category',
            data: Object.keys(data.data.monthNpsMap)
              .sort()
              .map((key) => key),
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value}%',
            },
          },
          series: [
            {
              data: Object.keys(data.data.monthNpsMap)
                .sort()
                .map((key) => data.data.monthNpsMap[key]),
              type: 'line',
              color: '#a8b3fb',
              smooth: true,
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#a8b3fb' },
                  { offset: 1, color: '#fff' },
                ]),
              },
            },
          ],
        };
      } catch (error) {
        console.log(error);
        this.trendOption = null;
      }
    },

    // 获取表格数据
    async getTableList() {
      try {
        const { data } = await this.$axios.post('/api/dada/recommend_cause', {
          complete_start_time: this.params.month?.[0],
          complete_end_time: this.params.month?.[1],
          shop_name: this.params.shop,
        });
        this.tableList = data.data;
      } catch (error) {
        console.log(error);
        this.tableList = [];
      }
    },

    // 推荐-推荐因子
    async getRecommendFactor() {
      try {
        const data = await this.$axios.post('/api/dada/recommend_factor', {
          complete_start_time: this.params.month?.[0],
          complete_end_time: this.params.month?.[1],
          shop_name: this.params.shop,
        });
        this.recommendFactors = data.data.data;
        setTimeout(() => {
          this.$refs.commonTabs.handleTab(this.tabs[0]);
        }, 100);
      } catch (e) {
        console.error(e);
        this.recommendFactors = [];
        setTimeout(() => {
          this.$refs.commonTabs.handleTab(this.tabs[0]);
        }, 100);
      }
    },

    // tab 切换更新图表操作
    updateHorizonChart(data) {
      if (!data) {
        this.causeOption = null;
        return;
      }
      this.causeOption = {
        legend: {
          data: ['不推荐原因', '推荐的原因'],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'value',
          },
        ],
        yAxis: [
          {
            type: 'category',
            axisTick: {
              show: false,
            },
            data: data.recommend_factor.map((x) => x.option_title),
          },
        ],
        series: [
          {
            name: '不推荐原因',
            type: 'bar',
            stack: 'reason',
            label: {
              show: true,
              position: 'left',
              formatter: (val) => val.data.toFixed(0) + '%',
            },
            data: data.recommend_factor.map(
              (x) => -Number(x.not_recommend_ratio)
            ),
            itemStyle: {
              color: '#F26E2B',
            },
          },
          {
            name: '推荐的原因',
            type: 'bar',
            stack: 'reason',
            label: {
              show: true,
              position: 'right',
              formatter: (val) => val.data.toFixed(0) + '%',
            },
            data: data.recommend_factor.map(
              (x) => Number(x.recommend_num_ratio) + 0.0001
            ),
            itemStyle: {
              color: '#4E7AFF',
            },
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.recommendation {
  padding: 16px;

  .ensemble {
    display: flex;
    justify-content: space-between;

    .e-card {
      width: calc(50% - 14px);
      border: 1px solid #ebebeb;
      border-radius: 4px;

      .ec-top {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        background: #fafcff;
        border-bottom: 1px solid #ebebeb;

        .ec-title {
          height: 32px;
          font-size: 16px;
          font-weight: 600;
          color: #111111;
          line-height: 32px;
          margin-right: 4px;
        }

        .icon {
          cursor: pointer;
        }
      }

      .ec-main {
        padding: 16px 0;

        > div {
          height: 260px;
        }
      }
    }
  }

  .trend > div {
    height: 334px;
  }

  .cause {
    border: 1px solid #e7ebf1;

    .cause-main {
      padding: 16px 24px;

      .c-top > div {
        height: 358px;
      }

      .c-center {
        margin-top: 24px;
        padding-top: 24px;
        margin-bottom: 30px;
        border-top: 1px dashed #999;

        > div {
          height: 452px;
        }
      }

      .c-bottom {
        .tr {
          display: flex;
          align-items: center;
          padding: 6px 10px;
          min-height: 43px;
          border-bottom: 1px solid #ebebeb;

          .td:first-child {
            flex: 8;
          }

          .td:last-child {
            flex: 10;
          }
        }

        .th {
          background: #fafafa;
        }

        .page {
          margin-top: 16px;
          text-align: right;
        }
      }
    }
  }
}
</style>
