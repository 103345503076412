<template>
  <div class="tabs">
    <div
      class="tab"
      :class="item === current ? 'active' : ''"
      v-for="item in list"
      :key="item.key"
      @click="handleTab(item)"
    >
      {{ item.value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: '',
    };
  },
  methods: {
    handleTab(item) {
      this.current = item;
      this.$emit('change', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  border-bottom: 1px solid #e7ebf1;

  .tab {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 32px;
    padding: 8px 26px;
    background: #f7f9fc;
    border-right: 1px solid #e7ebf1;
    cursor: pointer;
  }

  .active {
    color: #5980f7;
    background-color: #fff;
  }
}
</style>
