
/**
 * demo配置的问卷名和SN对应关系
 */
export const qdic = {
  "8Ype1JxD": "售前咨询",
  "XEBB6kE2": "下单成功",
  "7xryV0Yp": "物流到货",
  "wYbZGoY4": "产品体验",
  "ox6am5Y8": "售后咨询",
  "lERm1JxB": "问题解决",
  "RxLamlE0": "评价跟进"
};